















import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    IconAlertCircle: () => import('@/components/UI/icons/IconAlertCircle.vue'),
  },
})
export default class Informer extends Vue {

}
