import { render, staticRenderFns } from "./Informer.vue?vue&type=template&id=37d59124&scoped=true&"
import script from "./Informer.vue?vue&type=script&lang=ts&"
export * from "./Informer.vue?vue&type=script&lang=ts&"
import style0 from "./Informer.vue?vue&type=style&index=0&id=37d59124&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d59124",
  null
  
)

export default component.exports
import {QItem,QIcon,QItemLabel} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QIcon,QItemLabel})
